import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { PROJECT_MENU_ITEM } from '../constants';
import { MenuItem } from '../models';

@Injectable()
export class MenuItemsSignal {
  private _stateSignal: WritableSignal<MenuItem[]> = signal<MenuItem[]>([PROJECT_MENU_ITEM]);

  get stateSignal$(): Signal<MenuItem[]> {
    return this._stateSignal.asReadonly();
  }

  updateMenuItems(menuItems: MenuItem[]) {
    this._stateSignal.set(menuItems);
  }
}
